import { ErrorCode } from "@/constant";
import { Network } from "@/network";
import { Log } from "@/util";

class HomeworkModel {
    private static sInstance: HomeworkModel;

    public static get instance(): HomeworkModel {
        if (!HomeworkModel.sInstance) {
            HomeworkModel.sInstance = new HomeworkModel();
        }

        return HomeworkModel.sInstance;
    }
    // ------------------------------------ 題目 ------------------------------------
    // 新增題目 
    public async addQuestion(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.uploadFile("/api/admin/homework/addQuestion", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    //取得題目列表
    public async getQuestionList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/homework/getQuestionList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得 題目資訊
    public async getQuestion(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/homework/getQuestionMem" , "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯題目 
    public async editQuestion(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.uploadFile("/api/admin/homework/editQuestion", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 刪除題目
    public async delQuestion(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/homework/delQuestion", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // ------------------------------------ 組卷 ------------------------------------
    // 新增組卷
    public async addPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/homework/addPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 編輯組卷 
    public async editPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/homework/editPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    //取得組卷列表
    public async getPaperList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/homework/getPaperList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 刪除組卷
    public async delPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/homework/delPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 檢查該組卷是否已填寫
    public async checkPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/homework/checkPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 取得組卷資訊
    public async getPaper(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/homework/getPaperMem" , "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得試卷資訊＋題目列表
    public async getPaperInfo(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/homework/getPaperInfo", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得結果列表 
    public async getResultList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/homework/getResultList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //匯出測驗結果列表
    public async exportList(item: object): Promise<string> {
        let path = '';
        await Network.sendAPI("/api/admin/homework/exportList", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                path = data.filepath as string;
            })
            .catch(error => {
                Log.error(error);
            });
        return path;
    }

    // 撈測驗結果
    public async getResult(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/homework/getResultMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 老師評語
    public async verify(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/homework/verify", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得音檔列表 
    public async getAudioList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/homework/getAudioList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    /* ------------------------ 老師前台 ------------------------ */
    //取得題目列表
    public async teGetQuestionList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/homework/getQuestionList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 新增題目 
    public async teAddQuestion(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.uploadFile("/api/ap/teacher/homework/addQuestion", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    //取得 題目資訊
    public async teGetQuestion(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/homework/getQuestionMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯題目 
    public async teEditQuestion(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.uploadFile("/api/ap/teacher/homework/editQuestion", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 刪除題目
    public async teDelQuestion(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/ap/teacher/homework/delQuestion", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 新增組卷
    public async teAddPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/homework/addPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得組卷列表
    public async teGetPaperList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/homework/getPaperList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 刪除組卷
    public async teDelPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/homework/delPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 檢查該組卷是否已填寫
    public async teCheckPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/homework/checkPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 取得組卷資訊
    public async teGetPaper(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/homework/getPaperMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得題目列表
    public async teGetPaperInfo(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/homework/getPaperInfo", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }


    //取得測驗結果列表        
    public async teGetResultList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/homework/getResultList", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 撈測驗結果
    public async teGetResult(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/homework/getResultMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 老師評語
    public async teVerify(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/homework/verify", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    /* ------------------------ 學生前台 ------------------------ */
    //取得組卷列表
    public async stGetPaperList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/homework/getPaperList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 檢查該組卷該學生是否已填寫過，並撈出題目或題數
    public async checkWrite(item: object): Promise<object> {
        let data !: object
        await Network.sendAPI("/api/ap/student/homework/checkWrite", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    public async stGetPaperQuestion(item: object): Promise<object> {
        let data !: object
        await Network.sendAPI("/api/ap/student/homework/getPaperQuestion", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯答案
    public async stEditAnswer(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/student/homework/editAnswer", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得結果列表 
    public async stGetResultList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/homework/getResultList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 撈測驗結果
    public async stGetResultMem(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/homework/getResultMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 上傳音檔
    public async stUploadAudio(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.uploadFile("/api/ap/student/homework/uploadAudio", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

}

const homeworkModel = HomeworkModel.instance;

export { homeworkModel as HomeworkModel };
