import { ErrorCode } from "@/constant";
import { Network } from "@/network";
import { Log } from "@/util";

class SchoolModel {
    private static sInstance: SchoolModel;

    public static get instance(): SchoolModel {
        if (!SchoolModel.sInstance) {
            SchoolModel.sInstance = new SchoolModel();
        }

        return SchoolModel.sInstance;
    }

    // 新增分校 
    public async add(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/school/add", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得分校列表
    public async getList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/school/getList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得 分校資訊
    public async get(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/school/getMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯分校 
    public async edit(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/school/edit", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 刪除 分校
    public async del(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/school/del", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

}

const schoolModel = SchoolModel.instance;

export { schoolModel as SchoolModel };
