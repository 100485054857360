import { ErrorCode } from "@/constant";
import { Network } from "@/network";
import { Log } from "@/util";

class InstructionsModel {
    private static sInstance: InstructionsModel;

    public static get instance(): InstructionsModel {
        if (!InstructionsModel.sInstance) {
            InstructionsModel.sInstance = new InstructionsModel();
        }

        return InstructionsModel.sInstance;
    }

    //取得列表
    public async getList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/instructions/getList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得 資訊
    public async get(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/instructions/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯
    public async edit(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.uploadFile("/api/admin/instructions/edit", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }
    /* ------------------------ 老師前台 ---------------------- */
    //取得 資訊
    public async teGet(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/instructions/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    /* ------------------------ 學生前台 ---------------------- */
    //取得 資訊
    public async stGet(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/instructions/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

}

const instructionsModel = InstructionsModel.instance;

export { instructionsModel as InstructionsModel };
