import { ErrorCode } from "@/constant";
import { Network } from "@/network";
import { Log } from "@/util";

class TextbookModel {
    private static sInstance: TextbookModel;

    public static get instance(): TextbookModel {
        if (!TextbookModel.sInstance) {
            TextbookModel.sInstance = new TextbookModel();
        }

        return TextbookModel.sInstance;
    }

    // 新增教材
    public async add(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.uploadFile("/api/admin/textbook/add", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    //取得教材列表
    public async getList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/textbook/getList", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得 教材資訊
    public async get(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/textbook/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯教材 
    public async edit(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.uploadFile("/api/admin/textbook/edit", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 刪除教材
    public async del(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/textbook/del", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    /* ------------------------ 老師前台 ---------------------- */
    //取得教材列表
    public async teGetList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/textbook/getList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }
}

const textbookModel = TextbookModel.instance;

export { textbookModel as TextbookModel };
