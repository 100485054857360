import { Network } from "@/network";
import { pb } from "@/network/pb/proto";
import { Log } from "@/util";
import * as Constant from "@/constant";

class UserModel {
  private static sInstance: UserModel;

  public static get instance(): UserModel {
    if (!UserModel.sInstance) {
      UserModel.sInstance = new UserModel();
    }

    return UserModel.sInstance;
  }

  public async get(): Promise<object> {
    let data!: object;

    await Network.sendAPI("/api/test/user", "get")
      .then(response => {
        data = response.data as object;
      })
      .catch(error => {
        Log.error(error);
      });

    return data;
  }

  public async getByID(id: number): Promise<object> {
    let data!: object;

    await Network.sendAPI("/api/test/user/" + id, "get")
      .then(response => {
        data = response.data as object;
      })
      .catch(error => {
        Log.error(error);
      });

    return data;
  }

  public async add(item: object): Promise<number> {
    let errNo = Constant.ErrorCode.NotResponse;

    await Network.sendAPI("/api/test/user", "post", item)
      .then(response => {
        const data = response.data as { [key: string]: unknown };
        errNo = data.err_no as number;
      })
      .catch(error => {
        Log.error(error);
      });

    return errNo;
  }

  public async edit(id: number, item: object): Promise<number> {
    let errNo = Constant.ErrorCode.NotResponse;

    await Network.sendAPI("/api/test/user/" + id, "put", item)
      .then(response => {
        const data = response.data as { [key: string]: unknown };
        errNo = data.err_no as number;
      })
      .catch(error => {
        Log.error(error);
      });

    return errNo;
  }

  public async delete(id: number): Promise<number> {
    let errNo = Constant.ErrorCode.NotResponse;

    await Network.sendAPI("/api/test/user/" + id, "delete")
      .then(response => {
        const data = response.data as { [key: string]: unknown };
        errNo = data.err_no as number;
      })
      .catch(error => {
        Log.error(error);
      });

    return errNo;
  }

  public async addByPB(item: Uint8Array): Promise<number> {
    const errNo = Constant.ErrorCode.NotResponse;

    await Network.sendAPI("/api/test/pb", "post", item)
      .then(response => {
        pb.User.decode(new Uint8Array(response.data as ArrayBufferLike));
      })
      .catch(error => {
        Log.error(error);
      });

    return errNo;
  }
}

const userModel = UserModel.instance;

export { userModel as UserModel };
