import { Network } from "@/network";
import { Log } from "@/util";

class CommonModel {
    private static sInstance: CommonModel;

    public static get instance(): CommonModel {
        if (!CommonModel.sInstance) {
            CommonModel.sInstance = new CommonModel();
        }

        return CommonModel.sInstance;
    }

    //取得級數
    public async getGrade(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/textbook/getGrade", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得該級數下的等級
    public async getLevel(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/textbook/getLevel", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 取得所有縣市列表 
    public async getAllCity(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/school/getAllCity", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 取得該縣市的鄉鎮市區列表
    public async getRegion(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/school/getRegion", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 取得有分校之縣市
    public async getSchoolCity(item: object): Promise<object> {
        let data!: object;
        await Network.sendAPI("/api/admin/school/getSchoolCity", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 取得該縣市的分校列表 
    public async getSchool(item: object): Promise<object> {
        let data!: object;
        await Network.sendAPI("/api/admin/school/getSchool", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得 權限為分校之該分校縣市
    public async getCity(item: object): Promise<object> {
        let data!: object;
        await Network.sendAPI("/api/admin/school/getCity", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    /* -------------------------- 老師前台 ----------------------- */
    //取得級數
    public async teGetGrade(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/quiz/getGrade", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得該級數下的等級
    public async teGetLevel(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/quiz/getLevel", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 取得 該班級的老師列表
    public async teGetTeacher(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/quiz/getClassTeacher", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得該老師所屬班級的所有縣市
    public async getTECity(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/teacher/getTECity", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得該老師所屬班級之該縣市所屬分校
    public async getTESchool(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/teacher/getTESchool", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得該老師所屬班級之該分校的班級列表
    public async getTEClass(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/teacher/getTEClass", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }
}

const commonModel = CommonModel.instance;

export { commonModel as CommonModel };
