import { ErrorCode } from "@/constant";
import { Network } from "@/network";
import { Log } from "@/util";

class TeacherModel {
    private static sInstance: TeacherModel;

    public static get instance(): TeacherModel {
        if (!TeacherModel.sInstance) {
            TeacherModel.sInstance = new TeacherModel();
        }

        return TeacherModel.sInstance;
    }

    // 新增老師 
    public async add(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.uploadFile("/api/admin/teacher/add", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 取得老師列表 
    public async getList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/teacher/getList", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 刪除老師
    public async del(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/teacher/del", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    //取得 老師資訊 + 該老師所屬分校列表 
    public async get(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/teacher/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯老師
    public async edit(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.uploadFile("/api/admin/teacher/edit", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 編輯老師 密碼
    public async editPwd(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/teacher/editPwd", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    /* ------------------------ 老師前台 ---------------------- */
    //取得 老師資訊
    public async teGet(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/teacher/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯老師
    public async teEdit(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.uploadFile("/api/ap/teacher/teacher/edit", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 編輯老師 密碼
    public async teEditPwd(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/teacher/editPwd", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }
}

const teacherModel = TeacherModel.instance;

export { teacherModel as TeacherModel };
