import { ErrorCode } from "@/constant";
import { Network } from "@/network";
import { Log } from "@/util";

class ClassModel {
    private static sInstance: ClassModel;

    public static get instance(): ClassModel {
        if (!ClassModel.sInstance) {
            ClassModel.sInstance = new ClassModel();
        }

        return ClassModel.sInstance;
    }

    // 取得 該分校的可選老師
    public async getTeacher(item: object): Promise<object> {
        let data!: object;
        await Network.sendAPI("/api/admin/classes/getTeacher", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 新增班級
    public async add(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/classes/add", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得班級列表
    public async getList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/classes/getList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得 班級資訊        
    public async get(item: object): Promise<object> {
        let data!: object;
        await Network.sendAPI("/api/admin/classes/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯班級 
    public async edit(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/classes/edit", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 刪除班級
    public async del(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/classes/del", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 取得可選擇的學生列表 
    public async getAddStudentList(item: object): Promise<object> {
        let data!: object;
        await Network.sendAPI("/api/admin/classes/getAddStudentList", "post", item)
            .then(response => {
                data = response.data as object;
                // this.convertAddStudentListData(rawData);
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 新增學生至班級
    public async addStudent(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/classes/addStudent", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 取得此班級學生列表
    public async getStudentListData(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/classes/getStudentList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 刪除班級學生
    public async delStudent(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/classes/delStudent", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    /* ------------------------ 老師前台 ------------------------ */
    //取得班級列表
    public async teGetList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/classes/getList", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得 班級資訊        
    public async teGet(item: object): Promise<object> {
        let data!: object;
        await Network.sendAPI("/api/ap/teacher/classes/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 取得此班級學生列表
    public async teGetStudentList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/classes/getStudentList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }


    /* ------------------------ 學生前台 ------------------------ */
    //取得班級列表
    public async stGetList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/classes/getList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }
}

const classModel = ClassModel.instance;

export { classModel as ClassModel };
