import { ErrorCode } from "@/constant";
import { Network } from "@/network";
import { Log } from "@/util";

class AccountModel {
  private static sInstance: AccountModel;

  public static get instance(): AccountModel {
    if (!AccountModel.sInstance) {
      AccountModel.sInstance = new AccountModel();
    }

    return AccountModel.sInstance;
  }

  public async refreshCodeImg(item: object): Promise<string> {
    let data!: string;
    await Network.getImageSrcByPost("/api/admin/captcha/getCode", item) //post 取驗證碼圖，路徑要正確
      .then((src) => {
        data = src;
      })
      .catch((error) => {
        Log.error(error);
      });
    return data;
  }

  public async verificationCodeCheck(item: object): Promise<number> {
    let errNo = ErrorCode.NotResponse;

    await Network.sendAPI("/api/admin/captcha/checkCode", "post", item)
      .then((response) => {
        const data = response.data as { [key: string]: unknown }; //把回傳回來的map，值型態先設定為“未知”
        errNo = data.ERR_CODE as number; //再指定其該有的型態給他
      })
      .catch((error) => {
        Log.error(error);
      });

    return errNo;
  }

  public async userLogin(item: object): Promise<object> {
    let data!: object;
    await Network.sendAPI("/api/admin/index/check", "post", item)
      .then((response) => {
        data = response.data as { [key: string]: unknown };
      })
      .catch((error) => {
        Log.error(error);
      });
    return data;
  }

  // 新增帳號
  public async add(item: object): Promise<number> {
    let errNo = ErrorCode.NotResponse;
    await Network.sendAPI("/api/admin/account/add", "post", item)
      .then((response) => {
        const data = response.data as { [key: string]: unknown };
        errNo = data.ERR_CODE as number;
      })
      .catch((error) => {
        Log.error(error);
      });
    return errNo;
  }

  //取得 該身份&縣市下的分校
  public async getIdentitySchool(item: object): Promise<object> {
    let data!: object;
    await Network.sendAPI("/api/admin/account/getIdentitySchool", "post", item)
      .then((response) => {
        data = response.data as { [key: string]: unknown };
      })
      .catch((error) => {
        Log.error(error);
      });
    return data;
  }

  //取得帳號列表
  public async getList(item: object): Promise<object> {
    let data!: object;
    await Network.sendAPI("/api/admin/account/getList", "post", item)
      .then((response) => {
        data = response.data as { [key: string]: unknown };
      })
      .catch((error) => {
        Log.error(error);
      });
    return data;
  }

  // 刪除 帳號
  public async del(item: object): Promise<number> {
    let errNo = ErrorCode.NotResponse;

    await Network.sendAPI("/api/admin/account/del", "post", item)
      .then((response) => {
        const data = response.data as { [key: string]: unknown };
        errNo = data.ERR_CODE as number;
      })
      .catch((error) => {
        Log.error(error);
      });

    return errNo;
  }

  //取得 帳號資訊
  public async get(item: object): Promise<object> {
    let data!: object;
    await Network.sendAPI("/api/admin/account/getMem", "post", item)
      .then((response) => {
        data = response.data as object;
      })
      .catch((error) => {
        Log.error(error);
      });
    return data;
  }

  // 編輯帳號
  public async edit(item: object): Promise<number> {
    let errNo = ErrorCode.NotResponse;

    await Network.sendAPI("/api/admin/account/edit", "post", item)
      .then((response) => {
        const data = response.data as { [key: string]: unknown };
        errNo = data.ERR_CODE as number;
      })
      .catch((error) => {
        Log.error(error);
      });

    return errNo;
  }

  /* ------------------------ 老師前台 ------------------------ */
  //驗證登入
  public async teUserLogin(item: object): Promise<object> {
    let data!: object;
    await Network.sendAPI("/api/ap/teacher/index/check", "post", item)
      .then((response) => {
        data = response.data as { [key: string]: unknown };
      })
      .catch((error) => {
        Log.error(error);
      });

    return data;
  }

  //忘記密碼 寄信
  public async teForgetPwd(item: object): Promise<number> {
    let errNo = ErrorCode.NotResponse;
    await Network.sendAPI("/api/ap/teacher/index/forgetPwd", "post", item)
      .then((response) => {
        const data = response.data as { [key: string]: unknown };
        errNo = data.ERR_CODE as number;
      })
      .catch((error) => {
        Log.error(error);
      });
    return errNo;
  }

  //重設密碼
  public async teResetPwd(item: object): Promise<number> {
    let errNo = ErrorCode.NotResponse;
    await Network.sendAPI("/api/ap/teacher/index/resetPwd", "post", item)
      .then((response) => {
        const data = response.data as { [key: string]: unknown };
        errNo = data.ERR_CODE as number;
      })
      .catch((error) => {
        Log.error(error);
      });
    return errNo;
  }
  
  /* ------------------------ 學生前台 ------------------------ */
  //驗證登入
  public async stUserLogin(item: object): Promise<object> {
    let data!: object;
    await Network.sendAPI("/api/ap/student/index/check", "post", item)
      .then((response) => {
        data = response.data as { [key: string]: unknown };
      })
      .catch((error) => {
        Log.error(error);
      });

    return data;
  }

  //忘記密碼 寄信
  public async stForgetPwd(item: object): Promise<number> {
    let errNo = ErrorCode.NotResponse;
    await Network.sendAPI("/api/ap/student/index/forgetPwd", "post", item)
      .then((response) => {
        const data = response.data as { [key: string]: unknown };
        errNo = data.ERR_CODE as number;
      })
      .catch((error) => {
        Log.error(error);
      });
    return errNo;
  }

  //重設密碼
  public async stResetPwd(item: object): Promise<number> {
    let errNo = ErrorCode.NotResponse;
    await Network.sendAPI("/api/ap/student/index/resetPwd", "post", item)
      .then((response) => {
        const data = response.data as { [key: string]: unknown };
        errNo = data.ERR_CODE as number;
      })
      .catch((error) => {
        Log.error(error);
      });
    return errNo;
  }

  // 取得所有縣市列表
  public async stGetAllCity(item: object): Promise<object> {
    let data!: object;
    await Network.sendAPI("/api/ap/student/student/getAllCity", "post", item)
      .then((response) => {
        data = response.data as object;
      })
      .catch((error) => {
        Log.error(error);
      });
    return data;
  }

  // 取得該縣市的鄉鎮市區列表
  public async stGetRegion(item: object): Promise<object> {
    let data!: object;
    await Network.sendAPI("/api/ap/student/student/getRegion", "post", item)
      .then((response) => {
        data = response.data as object;
      })
      .catch((error) => {
        Log.error(error);
      });
    return data;
  }
}

const accountModel = AccountModel.instance;

export { accountModel as AccountModel };
