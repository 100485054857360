// define used error code
export enum ErrorCode {
    NotResponse = -1,

    // status
    Success = 1,
    Failed = 2,
    DataEmpty = 3,
    InvalidVerificationCode = 4,
    PicError = 5,
    PicSizeError = 6,
    InvalidToken = 7,
    InvalidExcelType = 8,

    // account
    UserNotExist = 1001,
    UserLoginInvalid = 1002,
    UserAccountDisable = 1003,
    UserAccountExist = 1004,
    UserResetPwdInvalid = 1005,
    UserResetPwdSuccess = 1006,
  
    // school
    SchoolExist = 2001,
    SchoolCanNotDel = 2002,

    // quiz
    QuestionAlreadyUse = 3001,

    // class
    ClassExist = 4001,
    ClassCanNotDel = 4002,

    ServerError = 10000
}

// define used error message (lang key)
export const ErrorMessage: { [key: number]: string } = { //指定型態
  [ErrorCode.Success]: "成功",
  [ErrorCode.Failed]: "失敗",
  [ErrorCode.DataEmpty]: "資料不存在",
  [ErrorCode.InvalidVerificationCode]: "驗證碼錯誤",
  [ErrorCode.PicError]: "圖片上傳錯誤",
  [ErrorCode.PicSizeError]: "請符合建議尺寸",
  [ErrorCode.InvalidExcelType]: "請上傳正確格式檔案",

  [ErrorCode.UserNotExist]: "帳號不存在",
  [ErrorCode.UserLoginInvalid]: "帳號或密碼錯誤",
  [ErrorCode.UserAccountDisable]: "帳號已停用",
  [ErrorCode.UserAccountExist]: "帳號已存在",
  [ErrorCode.UserResetPwdInvalid]: "密碼已被更改，請重新登入",
  [ErrorCode.UserResetPwdSuccess]: "已設定新密碼，請重新登入",

  [ErrorCode.SchoolExist]: "此名稱已存在",
  [ErrorCode.SchoolCanNotDel]: "此分校已有關聯資料，無法刪除",

  [ErrorCode.QuestionAlreadyUse]: "此題目已被組卷，無法刪除",

  [ErrorCode.ClassExist]: "此班級已存在",
  [ErrorCode.ClassCanNotDel]: "此班級已有關聯資料，無法刪除",

  [ErrorCode.ServerError]: "server_error"
};
