import { ErrorCode } from "@/constant";
import { Network } from "@/network";
import { Log } from "@/util";

class VideoModel {
    private static sInstance: VideoModel;

    public static get instance(): VideoModel {
        if (!VideoModel.sInstance) {
            VideoModel.sInstance = new VideoModel();
        }

        return VideoModel.sInstance;
    }

    // 新增
    public async add(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/video/add", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    //取得列表
    public async getList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/video/getList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 刪除
    public async del(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/video/del", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    //取得 資訊
    public async get(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/video/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯
    public async edit(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/video/edit", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    /* ------------------------ 學生前台 ---------------------- */
    //取得列表
    public async stGetList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/video/getList", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得 資訊
    public async stGet(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/video/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

}

const videoModel = VideoModel.instance;

export { videoModel as VideoModel };
