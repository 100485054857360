import { ErrorCode } from "@/constant";
import { Network } from "@/network";
import { Log } from "@/util";

class RollcallModel {
    private static sInstance: RollcallModel;

    public static get instance(): RollcallModel {
        if (!RollcallModel.sInstance) {
            RollcallModel.sInstance = new RollcallModel();
        }

        return RollcallModel.sInstance;
    }

    // 新增點名表 
    public async add(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/rollcall/add", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 取得點名表列表 
    public async getList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/rollcall/getList", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 刪除點名表
    public async del(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/rollcall/del", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得 點名表資訊        
    public async get(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/rollcall/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯點名表紀錄
    public async editRecord(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/rollcall/editRecord", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    /* ------------------------ 老師前台 ------------------------ */
    //取得點名列表
    public async teGetList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/rollcall/getList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 新增點名表 
    public async teAdd(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/rollcall/add", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 刪除點名表
    public async teDel(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/rollcall/del", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得 點名表資訊        
    public async teGet(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/rollcall/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯點名表紀錄
    public async teEditRecord(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/rollcall/editRecord", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    /* ------------------------ 學生前台 ------------------------ */
    //取得點名列表
    public async stGetList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/rollcall/getList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }
}

const rollcallModel = RollcallModel.instance;

export { rollcallModel as RollcallModel };
