import { ErrorCode } from "@/constant";
import { Network } from "@/network";
import { Log } from "@/util";

class QuizModel {
    private static sInstance: QuizModel;

    public static get instance(): QuizModel {
        if (!QuizModel.sInstance) {
            QuizModel.sInstance = new QuizModel();
        }

        return QuizModel.sInstance;
    }
    // ------------------------------------ 題目 ------------------------------------
    // 取得 依照登入者身分的可選老師
    public async getTeacher(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/quiz/getTeacher", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 新增題目 
    public async addQuestion(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.uploadFile("/api/admin/quiz/addQuestion", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    //取得題目列表
    public async getQuestionList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/quiz/getQuestionList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得 題目資訊
    public async getQuestion(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/quiz/getQuestionMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯題目 
    public async editQuestion(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.uploadFile("/api/admin/quiz/editQuestion", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 刪除題目
    public async delQuestion(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/quiz/delQuestion", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // ------------------------------------ 組卷 ------------------------------------
    // 取得 該分校的班級列表
    public async getClass(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/quiz/getClass", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 取得 該班級的老師列表
    public async getClassTeacher(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/quiz/getClassTeacher", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 新增組卷
    public async addPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/quiz/addPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得組卷列表
    public async getPaperList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/quiz/getPaperList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 刪除組卷
    public async delPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/quiz/delPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 檢查該組卷是否已填寫
    public async checkPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/quiz/checkPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 取得組卷資訊        
    public async getPaper(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/quiz/getPaperMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯組卷 
    public async editPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/quiz/editPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得試卷資訊
    public async getPaperInfo(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/quiz/getPaperInfo", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得測驗結果列表 
    public async getLearningResultList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/student/getLearningList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    public async getResultList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/quiz/getResultList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //匯出測驗結果列表
    public async exportList(item: object): Promise<string> {
        let path = '';
        await Network.sendAPI("/api/admin/quiz/exportList", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                path = data.filepath as string;
            })
            .catch(error => {
                Log.error(error);
            });
        return path;
    }

    // 撈測驗結果
    public async getResult(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/quiz/getResultMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 審核
    public async verify(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/admin/quiz/verify", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    /* ------------------------ 老師前台 ------------------------ */
    // 列出可選老師
    public async teGetAllTeacher(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/quiz/getAllTeacher", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 取得題目列表
    public async teGetQuestionList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/quiz/getQuestionList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 新增題目 
    public async teAddQuestion(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.uploadFile("/api/ap/teacher/quiz/addQuestion", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 刪除題目
    public async teDelQuestion(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/quiz/delQuestion", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得 題目資訊
    public async tGetQuestion(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/quiz/getQuestionMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯題目 
    public async teEditQuestion(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.uploadFile("/api/ap/teacher/quiz/editQuestion", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 新增組卷
    public async teAddPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/quiz/addPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 取得組卷列表
    public async teGetPaperList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/quiz/getPaperList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 檢查該組卷是否已填寫
    public async teCheckPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/quiz/checkPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 刪除組卷
    public async teDelPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/quiz/delPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 取得組卷資訊        
    public async teGetPaper(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/quiz/getPaperMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯組卷 
    public async teEditPaper(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/quiz/editPaper", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得試卷＋題目列表
    public async teGetPaperInfo(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/quiz/getPaperInfo", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得測驗結果列表        
    public async teGetResultList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/quiz/getResultList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 撈測驗結果
    public async teGetResult(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/quiz/getResultMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    public async teGetLearningList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/student/getLearningList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 審核
    public async teVerify(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/quiz/verify", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    /* ------------------------ 學生前台 ------------------------ */
    //取得組卷列表
    public async stGetPaperList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/quiz/getPaperList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 檢查該組卷該學生是否已填寫過，並撈出題目或題數
    public async checkWrite(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/quiz/checkWrite", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    public async stGetPaperQuestion(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/quiz/getPaperQuestion", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯答案
    public async stEditAnswer(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/student/quiz/editAnswer", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 編輯最後一題答案，完成測驗
    public async stCompleteQuiz(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/student/quiz/completeQuiz", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    // 撈測驗結果
    public async stGetResultMemData(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/quiz/getResultMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得測驗結果列表 
    public async stGetResultList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/quiz/getResultList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    public async stGetLearningList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/learning/getLearningList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

}

const quizModel = QuizModel.instance;

export { quizModel as QuizModel };
