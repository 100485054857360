import { ErrorCode } from "@/constant";
import { Network } from "@/network";
import { Log } from "@/util";

class StudentModel {
    private static sInstance: StudentModel;

    public static get instance(): StudentModel {
        if (!StudentModel.sInstance) {
            StudentModel.sInstance = new StudentModel();
        }

        return StudentModel.sInstance;
    }

    // 新增學生 
    public async add(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.uploadFile("/api/admin/student/add", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 取得學生列表 
    public async getList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/student/getList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 刪除學生
    public async del(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/student/del", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    //取得 學生資訊
    public async get(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/admin/student/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯學生
    public async edit(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.uploadFile("/api/admin/student/edit", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    // 編輯學生 密碼
    public async editPwd(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.sendAPI("/api/admin/student/editPwd", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    //匯出學生列表
    public async exportListData(item: object): Promise<string> {
        let path = '';

        await Network.sendAPI("/api/admin/student/exportList", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                path = data.filepath as string;
            })
            .catch(error => {
                Log.error(error);
            });
        return path;
    }

    //匯入學生列表
    public async importList(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;

        await Network.uploadFile("/api/admin/student/importList", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });

        return errNo;
    }

    /* ------------------------ 老師前台 ---------------------- */
    //取得學生列表
    public async teGetStudentList(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/student/getList", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    //取得學生資訊
    public async teGet(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/teacher/student/getMem", "post", item)
            .then(response => {
                data = response.data as { [key: string]: unknown };
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯學生
    public async teEditNote(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/teacher/student/editNote", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    /* ------------------------ 學生前台 ---------------------- */
    // 編輯學生
    public async stEdit(data: FormData): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.uploadFile("/api/ap/student/student/edit", data)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }

    //取得學生資訊
    public async stGet(item: object): Promise<object> {
        let data !: object;
        await Network.sendAPI("/api/ap/student/student/getMem", "post", item)
            .then(response => {
                data = response.data as object;
            })
            .catch(error => {
                Log.error(error);
            });
        return data;
    }

    // 編輯學生 密碼
    public async stEditPwd(item: object): Promise<number> {
        let errNo = ErrorCode.NotResponse;
        await Network.sendAPI("/api/ap/student/student/editPwd", "post", item)
            .then(response => {
                const data = response.data as { [key: string]: unknown };
                errNo = data.ERR_CODE as number;
            })
            .catch(error => {
                Log.error(error);
            });
        return errNo;
    }


}

const studentModel = StudentModel.instance;

export { studentModel as StudentModel };
